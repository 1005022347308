import React from "react";
import "./Timeline.css";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import icon1 from "../Home/1.png";
import certificate from "../../assets/certificate.png";

export default function Timeline() {
  "use strict";

  // define variables
  var items = document.querySelectorAll(".timeline li");

  function isElementInViewport(el) {
    var rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  function callbackFunc() {
    // console.log("I reached Pick me up");
    for (var i = 0; i < items.length; i++) {
      if (isElementInViewport(items[i])) {
        items[i].classList.add("in-view");
      }
    }
  }

  // listen for events
  window.addEventListener("load", callbackFunc);
  window.addEventListener("resize", callbackFunc);
  window.addEventListener("scroll", callbackFunc);

  return (
    <div>
      <div>
        <br />
        <br />
        <section className="intro">
          <div className="timeline-container">
            <center>
              <h1>Get certified through our Knowledge Transfer Methodology ↓</h1>
            </center>
            <br />
            <br />
          </div>
        </section>
        <section className="timeline">
          <ul>
            <li>
              <div
                data-aos="fade-up fade-left"
                data-aos-delay="50"
                data-aos-duration="1500"
                data-aos-easing="ease-in-out"
              >
                <h5 style={{ margin: 0 }}>Gap Assessment/Process Mapping</h5>
                <i
                  className="flaticon  flaticon-viral-marketing"
                  style={{
                    fontSize: "40px",
                    color: "#b9853f",
                    marginLeft: "20px",
                  }}
                />
              </div>
            </li>
            <li>
              <div
                data-aos="fade-up fade-right "
                data-aos-delay="70"
                data-aos-duration="1500"
                data-aos-easing="ease-in-out"
              >
                <h5 style={{ margin: 0 }}> Documentation</h5>
                <i
                  className="flaticon flaticon-talk-1"
                  style={{
                    fontSize: "40px",
                    color: "#b9853f",
                    marginLeft: "20px",
                  }}
                />
              </div>
            </li>
            <li>
              <div
                data-aos="fade-up fade-left"
                data-aos-delay="90"
                data-aos-duration="1500"
                data-aos-easing="ease-in-out"
              >
                <h5 style={{ margin: 0 }}>Trainings</h5>
                <i
                  className="flaticon flaticon-business-and-finance-1"
                  style={{
                    fontSize: "40px",
                    color: "#b9853f",
                    marginLeft: "20px",
                  }}
                />
              </div>
            </li>

            <li>
              <div
                data-aos="fade-up fade-right"
                data-aos-delay="110"
                data-aos-duration="1500"
                data-aos-easing="ease-in-out"
              >
                <h5 style={{ margin: 0 }}>Implementation</h5>
                {/* <i className="flaticon flaticon-marketing"  /> */}
                <img
                  src={icon1}
                  style={{
                    fontSize: "40px",
                    color: "#b9853f",
                    marginLeft: "20px",
                  }}
                />
              </div>
            </li>

            <li>
              <div
                data-aos="fade-up fade-left"
                data-aos-delay="130"
                data-aos-duration="1500"
                data-aos-easing="ease-in-out"
              >
                <h5 style={{ margin: 0 }}>Internal Audit</h5>
                <i
                  className="flaticon flaticon-marketing"
                  style={{
                    fontSize: "40px",
                    color: "#b9853f",
                    marginLeft: "20px",
                  }}
                />
                {/* <img src={icon1} /> */}
              </div>
            </li>

            <li>
              <div
                style={{ background: "none" }}
                data-aos="fade-up fade-right"
                data-aos-delay="150"
                data-aos-duration="1300"
                data-aos-easing="ease-in-out"
              >
                {/* <h5 style={{ margin:0 }}>Certification</h5>  */}
                {/* <i className="flaticon flaticon-marketing" style={{ fontSize : '40px', color : '#b9853f' , marginLeft : '20px' }}  /> */}
                <img
                  src={certificate}
                  style={{
                    width: "350px",
                    color: "#b9853f",
                    marginLeft: "20px",
                  }}
                />
              </div>
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
}
