import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";

export default function Footer() {
  return (
    <div>
      {/*footer start*/}
      <footer className="footer widget-footer clearfix">
        <div className="first-footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="first-footer-inner">
                  <div className="footer-logo">
                    <img
                      id="footer-logo-img"
                      className="img-center"
                      src={Logo}
                      alt="LOGO"
                    />
                  </div>
                  <div className="row no-gutters footer-box">
                    <div className="col-md-4 widget-area">
                      <div className="featured-box text-center">
                        <div className="featured-content">
                          <div className="featured-title">
                            <h5>Our Address</h5>
                          </div>
                          <div className="featured-desc">
                            <p>Evanto HQ 24 Fifth st., Los Angeles, USA</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 widget-area">
                      <div className="featured-box text-center">
                        <div className="featured-content">
                          <div className="featured-title">
                            <h5>Talk To Expert</h5>
                          </div>
                          <div className="featured-desc">
                            <p>+1234 567 8910 or +1234 567 8911</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 widget-area">
                      <div className="featured-box text-center">
                        <div className="featured-content">
                          <div className="featured-title">
                            <h5>Email Us</h5>
                          </div>
                          <div className="featured-desc">
                            <p>example@yourdomain.com</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-md-3 col-sm-2" />
                    <div className="col-md-6 col-sm-8">
                      <div className>
                        <form
                          id="subscribe-form"
                          className="newsletter-form"
                          method="post"
                          action="#"
                          data-mailchimp="true"
                        >
                          <div
                            className="mailchimp-inputbox clearfix"
                            id="subscribe-content"
                          >
                            <i className="fa fa-envelope-o" />
                            <input
                              type="email"
                              name="email"
                              placeholder="Your Email Address.."
                              required
                            />
                            <input type="submit" defaultValue="SUBMIT" />
                          </div>
                          <div id="subscribe-msg" />
                        </form>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="second-footer ttm-textcolor-white">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 widget-area">
                <div className="widget widget_text  clearfix">
                  <h3 className="widget-title">About Business</h3>
                  <div className="textwidget widget-text" >
                    As a business consultant, we focus on delivering
                    quantifiable results for our customers, based on a well
                    tested methodology and solid experience.
                    <br />
                    Based on a well tested methodology and solid experience.
                    <br />
                    <br />
                    <div className="social-icons circle social-hover">
                      <ul className="list-inline">
                        <li className="social-facebook">
                          <a
                            className="tooltip-top"
                            target="_blank"
                            href="https://www.facebook.com/mehrglobal/"
                            data-tooltip="Facebook"
                          >
                            <i className="fa fa-facebook" aria-hidden="true" />
                          </a>
                        </li>
                        <li className="social-twitter">
                          <a
                            className="tooltip-top"
                            target="_blank"
                            href="https://mobile.twitter.com/mehrglobal1"
                            data-tooltip="Twitter"
                          >
                            <i className="fa fa-twitter" aria-hidden="true" />
                          </a>
                        </li>

                        <li className="social-linkedin">
                          <a
                            className=" tooltip-top"
                            target="_blank"
                            href="https://www.linkedin.com/company/mehr-global-consultancy-pvt.-ltd/"
                            data-tooltip="LinkedIn"
                          >
                            <i className="fa fa-linkedin" aria-hidden="true" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <br />
                    <div className="mb-20">
                      <Link
                        className="ttm-btn ttm-btn-size-xs ttm-btn-shape-square ttm-btn-style-fill ttm-btn-bgcolor-skincolor ttm-btn-color-white"
                        to="/about"
                      >
                        READ MORE
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 widget-area">
                <div className="widget widget_nav_menu clearfix">
                  <h3 className="widget-title">Quick Links</h3>
                  <ul id="menu-footer-services">
                    <li>
                      <a href="/about">About Us</a>
                    </li>
                    <li>
                      <a href="/consultancy">Consultancy Services</a>
                    </li>
                    <li>
                      <a href="/training">Trainings</a>
                    </li>
                    <li>
                      <a href="/clients">Client Reviews</a>
                    </li>
                    <li>
                      <a href="/gallery">Gallery</a>
                    </li>
                    <li>
                      <a href="/contact">Contact Us</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 widget-area">
                <div className="widget flicker_widget clearfix">
                  <h3 className="widget-title">Our Branches</h3>
                  <div className="textwidget widget-text">
                    <img
                      src="images/map-footer.png"
                      className="img-fluid"
                      alt="map-footer"
                    />
                    <br />

                    <ul className="ttm-our-location-list">
                      <li>
                        <i className="fa fa-envelope" />
                        Email : info@mehrconsultants.com
                      </li>
                      <li>
                        <i className="fa fa-phone" />
                        Fax : +91 7727820454, +91 80 94000255
                      </li>
                      <li>
                        <i className="fa fa-fax" />
                        Tel : 0114779019, Fax : 0114744535{" "}
                      </li>

                      {/* <li><i className="fa fa-map-marker" />UAE: uae@mehrconsultants.com</li> */}
                      {/* <li><i className="fa fa-map-marker" />Canada: canada@mehrconsultants.com</li>
                      <li><i className="fa fa-map-marker" />Egpyt: egypt@mehrconsultants.com</li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-footer-text ttm-textcolor-white">
          <div className="container">
            <div className="row copyright">
              <div className="col-md-12">
                <div className>
                  <span>
                    Designed with <i class="fa fa-heart" /> By{" "}
                    <a href="https://www.thebrandwick.com/">TheBrandwick.</a>
                  </span>
                </div>
              </div>
              {/* <div className="col-md-12">
                <ul id="menu-footer-menu" className="footer-nav-menu">
                  <li><a href="#">About Us</a></li>
                  <li><a href="#">Services</a></li>
                  <li><a href="#">Privacy</a></li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </footer>
      {/*footer end*/}
    </div>
  );
}
