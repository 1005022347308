import React, { useRef } from "react";
import { useRecoilState } from "recoil";
import { consultancyState } from "./Atoms";

export default function ConsultancyInnerPage() {
  const [myconsultancyState, setmyconsultancyState] = useRecoilState(
    consultancyState
  );
  const data = myconsultancyState.data;

  console.log("Data", data);

  window.scrollTo(0, 0);

  return (
    <div style={{ color: "black" }}>
      <div className="container" style={{ padding: "20px" }}>
        <div>
          <img
            src={data.thumb}
            style={{ width: "100%", maxHeight: "50vh", objectFit: "contain" }}
          />

          <center>
            <h3 style={{ color : '#0B0C26' }}>
              <br />
              {data.isoName}
            </h3>
          </center>
        </div>
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <br />

            <div align="justify" style={{ fontSize : '16px' }} dangerouslySetInnerHTML={{ __html: data.isoData }}></div>
          </div>
        </div>
      </div>

      {/* <h2>Hello inner page :  { data &&  data.isoName }</h2> */}
    </div>
  );
}
