import React from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";

export default function Navbar() {
  const navbarClose = () => {
    const navbar = document.getElementById("collapsibleNavbar");
    navbar.classList.toggle("show");
  };

  return (
    <div>
      <div className="top-bar">
        <div className="top-bar-left">
          {" "}
          Mehr Global Consultancy Pvt Ltd.
        </div>
        <div className="top-bar-right">
          <div>
            {" "}
            <i class="fa fa-envelope" /> info@mehrconsultants.com
          </div>

          <div>
            <a href="">
              <i class="fa fa-phone" /> +91-8094000255
            </a>
          </div>

          <a target="_blank" href="https://www.facebook.com/mehrglobal/">
            <div>
              <i className="fa fa-facebook" aria-hidden="true" />
            </div>
          </a>

          <a
            target="_blank"
            href="https://www.linkedin.com/company/mehr-global-consultancy-pvt.-ltd/"
          >
            <div>
              <i class="fa fa-linkedin" />
            </div>
          </a>

          <a target="_blank" href="https://mobile.twitter.com/mehrglobal1">
            <div>
              <i class="fa fa-twitter" />
            </div>
          </a>
        </div>
      </div>

      <nav className="navbar navbar-expand-md bg-light navbar-light">
        <Link className="navbar-brand" to="/">
          <img src={Logo} alt="MEHR LOGO" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#collapsibleNavbar"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse " id="collapsibleNavbar">
          <ul className="navbar-nav ml-auto">
            {/* <li className="nav-item">
              <a className="nav-link" href="/">Home</a>
            </li> */}

            <li className="nav-item">
              <Link onClick={navbarClose} className="nav-link" to="about">
                About
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/consultancy">
                Consultancy
              </Link>
            </li>



            <li className="nav-item">
              <Link onClick={navbarClose} className="nav-link" to="training">
                Training
              </Link>
            </li>

           

            <li className="nav-item">
              <Link onClick={navbarClose} className="nav-link" to="eqms">
                e-QMS
              </Link>
            </li>




            <li className="nav-item">
              <Link onClick={navbarClose} className="nav-link" to="/legal">
                Legal Services
              </Link>
            </li>


            <li className="nav-item">
              <Link onClick={navbarClose} className="nav-link" to="/clients">
                Clients
              </Link>
            </li>


            <li className="nav-item">
              <Link className="nav-link" to="/gallery">
                Gallery
              </Link>
            </li>

            <li className="nav-item">
              <Link onClick={navbarClose} className="nav-link" to="contact">
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}
