import React from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { consultancyState } from "./Atoms";

export default function TrainingCards({ data }) {
  const [myconsultancyState, setmyconsultancyState] = useRecoilState(consultancyState);

  return (
    <div>
      <div className="card iso-card consultancy-card">
        <img
          className="card-img-top consultancy-img"
          src={data.thumb}
          alt="Card image cap"
        />
        <div className="card-body">
          <h5 className="card-title">{data.isoName}</h5>

          <p style={{ color: "#232323", textTransform: "capitalize" }}>
            <p
              dangerouslySetInnerHTML={{
                __html: data.isoData.slice(0, 68) + "...",
              }}
            ></p>
            <a   onClick={() =>
              setmyconsultancyState({
                data: data,
              })
            }
            href="/consultancyinnerpage"  style={{ color : '#b9853f' ,marginTop : '-5px'}}>Read more</a>
          </p>
          <a
            onClick={() =>
              setmyconsultancyState({
                data: data,
              })
            }
            href="/consultancyinnerpage"
            className="btn btn-primary"
            style={{ backgroundColor : '#0b0c26' , border:'none' }}
          >
            View Details
          </a>
        </div>
      </div>
    </div>
  );
}
