import Home from './components/Home/Home'
import Navbar from './components/Navbar/Navbar'
import Contact from './components/Contact/Contact'
import Footer from './components/Footer/Footer'
import { BrowserRouter as Router,Switch,Route,Link } from "react-router-dom";
import Gallery from './components/Gallery/Gallery';
import About from './components/About/About';
import Training from './components/Training/Training'
import ConsultancyCardsContainer from './components/ConsultancyCards/ConsultancyCardsContainer';
import SubTraining from './components/Training/SubTraining';
import ContentPage from './components/Training/ContentPage'
import Legal from './components/Pages/Legal'
import Eqms from './components/Pages/Eqms';
import ConsultancyInnerPage from './components/ConsultancyCards/ConsultancyInnerPage'
import ClientReview from './components/ClientReview/ClientReview';
import UpcomingTraining from './components/UpcomingTraining/UpcomingTraining';


function App() {
  return (
    <Router>

        <div className="App">
          <Navbar />

          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/gallery" component={Gallery} />
            <Route exact path="/about" component={About} />
            <Route exact path="/training" component={Training} />
            <Route exact path="/iso" component={ContentPage} />
            <Route exact path="/legal" component={Legal} />
            <Route exact path="/eqms" component={Eqms} />
            <Route exact path="/subtrainingcontentpage" component={ContentPage} />
            <Route exact path="/clients" component={ClientReview} />
            <Route exact path="/upcomingtraining" component={UpcomingTraining} />
           
            <Route exact path="/contact" component={Contact} />
            <Route
              exact
              path="/consultancy"
              component={ConsultancyCardsContainer} />
            <Route
              exact
              path="/consultancyinnerpage"
              component={ConsultancyInnerPage}
            />

          <Route
            exact
            path="/subtraining"
            component={SubTraining}
          />


          </Switch>

          <Footer />
        </div>
 
    </Router>
  );
}

export default App;
