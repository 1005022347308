import React, { useEffect, useState } from "react";
import SubTrainingCard from './SubTrainingCard'
import { getAllTestimonials, getAllTrainingPackages } from "../../Api";
import { useRecoilState } from "recoil";
import { trainingPackagesCategory } from "../ConsultancyCards/Atoms";

export default function SubTraining() {


  const data = [
    {
      title: "ISO 9001 2015",
      imageUrl:
        "https://image.shutterstock.com/image-vector/iso-9001-certified-symbol-260nw-1256159014.jpg",
      url: "/iso",
    },
    {
      title: "ISO 14001 2015",
      imageUrl:
        "https://5.imimg.com/data5/IU/TK/AD/SELLER-87223602/iso-14001-2015-certification-environmental-management-system--500x500.jpg",
      url: "/iso",
    },
    {
      title: "ISO 22000 2018",
      imageUrl:
        "https://5.imimg.com/data5/ZE/JQ/WA/SELLER-87223602/iso-22000-certification-food-safety-management-system--500x500.png",
      url: "/iso",
    },
    {
      title: "ISO 45001 2018",
      imageUrl:
        "https://5.imimg.com/data5/SELLER/Default/2020/8/YR/BH/PH/44636240/iso-45001-2018-occupational-health-and-safety-management-system-500x500.png",
      url: "/iso",
    },
    {
      title: "ISO 22301",
      imageUrl:
        "https://5.imimg.com/data5/OK/AE/PI/SELLER-4091933/iso-22301-certification-500x500-500x500-500x500.jpg",
      url: "/iso",
    },
    {
      title: "ISO 27001",
      imageUrl:
        "https://www.axiomsl.com/wp-content/uploads/2019/04/img-Logo-ISO-27001-800x450.jpg",
      url: "/iso",
    },
  ];

  const [
    selectedtrainingPackagesCategory,
    setselectedtrainingPackagesCategory,
  ] = useRecoilState(trainingPackagesCategory);

  const [categoryList, setcategoryList] = useState(null);





  useEffect(() => {
    
    console.log("Selected package is ", selectedtrainingPackagesCategory);
    setcategoryList(selectedtrainingPackagesCategory.listOfCategories) ;

    return () => {};
  }, []);

  return (
    <div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-5 col-sm-12">
            {/* section title */}
            <div className="section-title text-center with-desc clearfix">
              <div className="col-lg-12">
                {/* <h5>how it work</h5> */}
                <h4 style={{ marginTop: "20px" }}>Training Packages for {selectedtrainingPackagesCategory && selectedtrainingPackagesCategory.trainingHeading}</h4>
              </div>
            </div>
            {/* section title end */}
          </div>
        </div>
      </div>

      <div
        className="container"
        style={{ height: "auto", minHeight: "100vh", marginBottom: "10vh" }}
      >
        <div className="row">
          {categoryList &&
            categoryList.map((item, index) => {
              return (
                <div className="col-lg-3 col-md-6" key={index}>
                  <SubTrainingCard data={item.categoryId}  />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
