import React, { useEffect, useState } from "react";
import ConsultancyCard from "./ConsultancyCard";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { getAllServices } from "../../Api";
import { useRecoilState } from "recoil";
import { consultancyState } from "./Atoms";
import Loader from "react-loader-spinner";

export default function ConsultancyCardsContainer() {
  const [currentIndex, setcurrentIndex] = useState(0);
  const [serviceData, setserviceData] = useState(null);
  const [allServiceData, setallServiceData] = useState(null);
  const [selectedCategory, setselectedCategory] = useState("All Consultancy");

  const [firstloading, setfirstloading] = useState(true)

  const [myconsultancyState, setmyconsultancyState] = useRecoilState(
    consultancyState
  );

  const [loading, setloading] = useState(true);



  useEffect(() => {
    getServicesData();
  
    return () => {};
  }, [1]);

  const getServicesData = async () => {
    const res = await getAllServices();
    console.log("Services are =>", res.data);

    setallServiceData(res.data);
    setloading(false);

    setcurrentIndex(-1)

    console.log("Selected Category ISo are =>", res.data[currentIndex].iso);

    setmyconsultancyState({
      data: res.data[0].iso[0],
    });
  };

  useEffect(() => {
    if (allServiceData && currentIndex >= 0) {
      console.log("Selected iso are =>", allServiceData[currentIndex].iso);

      setselectedCategory(allServiceData[currentIndex].serviceName);
      setserviceData(allServiceData[currentIndex].iso);
    }

    if(allServiceData && currentIndex<0 ){
      setselectedCategory('All Consultancy')
      var temp = []
      var tempISO = []
      allServiceData.forEach(element => {
        // console.log(element.iso);

      element.iso.forEach(element => {
        tempISO.push(element)
      });
      
      });
      setserviceData(tempISO)

    }

    return () => {};
  }, [currentIndex]);

  const data = [
    {
      category: "Quality Management",
      subcategory: [
        {
          title: "ISO 9001:2015",
          desc: "Providing ISO for Quality Check.",
          imageUrl:
            "https://image.freepik.com/free-vector/iso-9001-certification-checks_23-2148681367.jpg",
        },
      ],
    },
    {
      category: "Energy Management",
      subcategory: [
        {
          title: "ISO 50001:2018",
          desc: "Energy Management ISO for Companies.",
          imageUrl:
            "https://image.freepik.com/free-vector/concept-smart-urban-city-with-server-room-database-icons-data-center-database_39422-628.jpg",
        },
      ],
    },
    {
      category: "Food Safety & HACCP",
      subcategory: [
        {
          title: "ISO 22000:2018",
          desc: "Requirement for all Food Industry.",
          imageUrl:
            "https://image.freepik.com/free-vector/genetically-modified-foods-abstract-concept-illustration-genetically-modified-organism-gm-food-industry-biotech-product-health-issue-nutrition-safety-disease-risk_335657-568.jpg",
        },
        {
          title: "HACCP",
          desc: "HACCP Certification for Agriculture.",
          imageUrl:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSk88uL35GWpn_elZWT4jsp1XVio1lTL2F7JA&usqp=CAU",
        },
        {
          title: "HALAL",
          desc: "Aimed at Muslim Majority Countries Food Standards.",
          imageUrl:
            "https://image.freepik.com/free-vector/happy-hijab-woman-showing-ketupat-both-hands-ramadan-mubarak-eid-al-fitr-holiday-flat-style-design_185694-32.jpg",
        },
      ],
    },
  ];

  return (
    <div>
      <div
        className="container-fluid gallery-container"
        style={{ cursor: "pointer" }}
      >
        <div className="row">
          <div className="col-md-12 ">
            <center>
              <br />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "center",
                  flexWrap : 'wrap'
                }}
              >
                <h5>Showing Consultancy for : &nbsp;</h5>
                <div className="dropdown">
                  <button
                    className="btn btn-primary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{backgroundColor : '#b9853f',border : 'none'}}
                  >
                    {selectedCategory && selectedCategory}
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >


<li onClick={() => setcurrentIndex(-1)}>
  <a className="dropdown-item">All Consultancy</a>
</li>


                    {allServiceData &&
                      allServiceData.map((item, index) => {
                        return (
                          <li
                            key={index}
                            onClick={() => setcurrentIndex(index)}
                          >
                            <a className="dropdown-item">{item.serviceName}</a>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>

              {/* this is a loader for data loading */}
              <center>
              <br />
           
                {loading && (
                  
                  <Loader
                    type="Bars"
                    color="#0B0C26"
                    height={100}
                    width={100}
                  />
                )}
              </center>

              <div
                className="col-lg-12"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: 'center',
                }}
              >
                {serviceData &&
                  serviceData.map((item, index) => {
                    return (
                      <div className="col-lg-3" style={{ textAlign: "left" }}>
                        {" "}
                        <ConsultancyCard data={item} />{" "}
                      </div>
                    );
                  })}
              </div>



{ !loading && <div><br/>
<br/>
    <a href="https://wa.link/iot87d">
    <button className=" content-page-button" style={{ backgroundColor: "#00bfa5" }}>
     Many More
    </button>
  </a>
  </div>
}




            </center>
          </div>
        </div>
      </div>
    </div>
  );
}
