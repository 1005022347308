import React from "react";

export default function Contact() {
  return (
    <div>
      {/* page-title */}
      <div className="ttm-page-title-row">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box ttm-textcolor-white">
                <div className="page-title-heading">
                  <h1 className="title">Contact Us</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a title="Homepage" href="index.html">
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;Home
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; | &nbsp;</span>
                  <span>Contact Us</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>
      {/* page-title end*/}

      {/*site-main start*/}
      <div className="site-main">
        {/* map-section */}
        <div className="ttm-row map-section clearfix">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-12 justify-content-center">
                {/*map-start*/}
                {/* <div className="map-wrapper">
                    <div id="map_canvas" />
                  </div> */}
                {/*map-end*/}

                {/* <div style={{ marginBottom: "10vh" }}>
                  <div>
                    <iframe
                      width={1280}
                      height={500}
                      frameBorder={0}
                      scrolling="no"
                      marginHeight={0}
                      marginWidth={0}
                      id="gmap_canvas"
                      src="https://maps.google.com/maps?width=1280&height=500&hl=en&q=%20JAIPUR+(MEHR%20GLOBAL%20CONSULTANCY)&t=&z=12&ie=UTF8&iwloc=B&output=embed"
                    />
                    <a href="https://www.embedmap.net/"></a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* map-section end */}
        {/* contact-form-section */}
        <section className="ttm-row contact-form-section clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="spacing-6 clearfix">
                  {/* section title */}
                  <div className="section-title clearfix">
                    <div className="title-header">
                      <h3 className="title">
                        We’re Happy to Discuss & Answer any Question.
                      </h3>
                    </div>
                  </div>
                  {/* section title end */}
                  <ul className="ttm_contact_widget_wrapper">
                    <li>
                      <h6>Address</h6>
                      <i className="ttm-textcolor-skincolor ti ti-location-pin" />
                      <span align="justify">
                        167/8, 3rd floor,Mandir Lane,Jullena,<br/> Okhla Road.
                       
                        New Delhi 110025
                      </span>
                    </li>
                    <li>
                      <h6>Email</h6>
                      <i className="ttm-textcolor-skincolor ti ti-comment" />
                      <span>
                        <a href="mailto:info@mehrconsultants.com">
                          info@mehrconsultants.com
                        </a>
                      </span>
                    </li>
                    <li>
                      <h6>Phone Numbers</h6>
                      <i className="ttm-textcolor-skincolor ti ti-mobile" />
                      <span>+91 7727820454, +91 80 94000255 </span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-6">
                <div className=" ttm-bgcolor-skincolor spacing-7">
                  {/* section title */}
                  <div className="section-title text-left with-desc clearfix">
                    <div className="title-header">
                      <h2 className="title">Let’s Start Conversation.</h2>
                    </div>
                  </div>
                  {/* section title end */}
                  <form
                    id="ttm-contactform"
                    className="ttm-contactform wrap-form clearfix"
                    name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field"
                 
                  >
                      <input type="hidden" name="form-name" value="contact" />

                    <div className="row">
                      <div className="col-md-6">
                        <label>
                          <span className="text-input">
                            <input
                              name="your-name"
                              type="text"
                              placeholder="Your Name"
                              required="required"
                              style={{ backgroundColor : 'transparent !important' }}
                            />
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label>
                          <span className="text-input">
                            <input
                              name="email"
                              type="email"
                              placeholder="Your Email"
                              required="required"
                            />
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label>
                          <span className="text-input">
                            <input
                              name="your-phone"
                              type="text"
                              placeholder="Your Phone"
                              required="required"
                            />
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label>
                          <span className="text-input">
                            <input
                              name="subject"
                              type="text"
                              placeholder="Subject"
                              required="required"
                            />
                          </span>
                        </label>
                      </div>
                    </div>
                    <label>
                      <span className="text-input">
                        <textarea
                          name="message"
                          cols={40}
                          rows={3}
                          placeholder="Message"
                          required="required"
                          defaultValue={""}
                        />
                      </span>
                    </label>
              
                      <button  className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-bgcolor-darkgrey" type="submit">Send</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* contact-form-section END*/}
      </div>
      {/*site-main end*/}

      <div className="container-fluid" style={{ padding: 60 }}>
        <div className="row" style={{ paddingTop: "5vh" }}>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div className="section-title clearfix">
              <div className="title-header">
                <h3 className="title">Saudi Arabia</h3>
              </div>
            </div>
            {/* section title end */}
            <ul className="ttm_contact_widget_wrapper">
              <li>
                <h6>Address</h6>
                <i className="ttm-textcolor-skincolor ti ti-location-pin" />
                <span>Al Dabab Street, P.O.Box: 038503, Riyadh: 11355</span>
              </li>
              <li>
                <h6>Email</h6>
                <i className="ttm-textcolor-skincolor ti ti-comment" />
                <span>
                  <a href="mailto:info@boldman.com">info@mehrconsultants.com</a>
                </span>
              </li>
              <li>
                <h6>Contact Number</h6>
                <i className="ttm-textcolor-skincolor ti ti-mobile" />
                <span>
                  {" "}
                  Tel : 0114779019, Fax : 0114744535 Mob : +966 507985330
                </span>
              </li>
            </ul>
          </div>

          <div
            className="col-lg-3 col-md-3 col-sm-12"
            style={{ overflow: "hidden" }}
          >
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe
                  width={300}
                  height={350}
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=Al%20Dabbab%20Street%20Riyadh%3A&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  frameBorder={0}
                  scrolling="no"
                  marginHeight={0}
                  marginWidth={0}
                />
              </div>
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    ".mapouter{position:relative;text-align:right;height:500px;width:600px;}.gmap_canvas {overflow:hidden;background:none!important;height:500px;width:600px;}",
                }}
              />
            </div>
          </div>

          <div
            className="col-lg-3 col-md-3 col-sm-12"
            style={{ paddingLeft: "5vw" }}
          >
            <div className="section-title clearfix">
              <div className="title-header">
                <h3 className="title">New Delhi </h3>
              </div>
            </div>
            {/* section title end */}
            <ul className="ttm_contact_widget_wrapper">
              <li>
                <h6>Address</h6>
                <i className="ttm-textcolor-skincolor ti ti-location-pin" />
                <span>
                  D-4, Bharat Nagar, New Friends Colony <br />
                  New Delhi-110025 India
                </span>
              </li>
              <li>
                <h6>Email</h6>
                <i className="ttm-textcolor-skincolor ti ti-comment" />
                <span>
                  <a href="mailto:info@boldman.com">info@mehrconsultants.com</a>
                </span>
              </li>
              <li>
                <h6>Phone Number</h6>
                <i className="ttm-textcolor-skincolor ti ti-mobile" />
                <span>+91 7727820454, +91 8094000255</span>
              </li>
            </ul>
          </div>
          <div
            className="col-lg-3 col-md-3 col-sm-12"
            style={{ overflow: "hidden" }}
          >
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe
                  width={300}
                  height={350}
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=okhla%20road%2Cnew%20delhi&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  frameBorder={0}
                  scrolling="no"
                  marginHeight={0}
                  marginWidth={0}
                />
                <a href="https://www.embedgooglemap.net"></a>
              </div>
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    ".mapouter{position:relative;text-align:right;height:250px;width:300px;}.gmap_canvas {overflow:hidden;background:none!important;height:250px;width:300px;}",
                }}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div className="section-title clearfix">
              <div className="title-header">
                <h3 className="title">Jaipur Office</h3>
              </div>
            </div>
            {/* section title end */}
            <ul className="ttm_contact_widget_wrapper">
              <li>
                <h6>Address</h6>
                <i className="ttm-textcolor-skincolor ti ti-location-pin" />
                <span>
                  A-25, Nandpuri,Lane-6, Near Baees Godam, Hawa Sadak <br />
                  Jaipur : 302006 (Rajasthan) India
                </span>
              </li>
              <li>
                <h6>Email</h6>
                <i className="ttm-textcolor-skincolor ti ti-comment" />
                <span>
                  <a href="mailto:info@mehrconsultants.com">
                    info@mehrconsultants.com
                  </a>
                </span>
              </li>
              <li>
                <h6>Phone Number</h6>
                <i className="ttm-textcolor-skincolor ti ti-mobile" />
                <span> +91 9829833394</span>
              </li>
            </ul>
          </div>

          <div
            className="col-lg-3 col-md-3 col-sm-12"
            style={{ overflow: "hidden" }}
          >
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe
                  width={300}
                  height={350}
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=%20Nandpuri%2Chawa%20sadak&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  frameBorder={0}
                  scrolling="no"
                  marginHeight={0}
                  marginWidth={0}
                />
                <a href="https://www.embedgooglemap.net">embedgooglemap.net</a>
              </div>
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    ".mapouter{position:relative;text-align:right;height:500px;width:600px;}.gmap_canvas {overflow:hidden;background:none!important;height:500px;width:600px;}",
                }}
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12"  style={{ paddingLeft: "5vw" }}>
            <div className="section-title clearfix">
              <div className="title-header">
                <h3 className="title">Rotterdam Office</h3>
              </div>
            </div>
            {/* section title end */}
            <ul className="ttm_contact_widget_wrapper">
              <li>
                <h6>Address</h6>
                <i className="ttm-textcolor-skincolor ti ti-location-pin" />
                <span>
                Burgemeester Roosstraat 38B 2e.<br />
                Rotterdam 
                </span>
              </li>
              <li>
                <h6>Email</h6>
                <i className="ttm-textcolor-skincolor ti ti-comment" />
                <span>
                  <a href="mailto:info@mehrconsultants.com">
                    info@mehrconsultants.com
                  </a>
                </span>
              </li>
              <li>
                <h6>Phone Number</h6>
                <i className="ttm-textcolor-skincolor ti ti-mobile" />
                <span> +316 84660952</span>
              </li>
            </ul>
          </div>

          <div
            className="col-lg-3 col-md-3 col-sm-12"
            style={{ overflow: "hidden" }}
          >
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe
                  width={300}
                  height={350}
                  id="gmap_canvas"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2460.1525600206833!2d4.476242615785594!3d51.93117077970697!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c434aa049af42f%3A0xaec720ca483773de!2sBurgemeester%20Roosstraat%2060%2C%203035%20EM%20Rotterdam%2C%20Netherlands!5e0!3m2!1sen!2sin!4v1609340407585!5m2!1sen!2sin"
        
                  frameBorder={0}
                  scrolling="no"
                  marginHeight={0}
                  marginWidth={0}
                />
                <a href="https://www.embedgooglemap.net">embedgooglemap.net</a>
              </div>
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    ".mapouter{position:relative;text-align:right;height:500px;width:600px;}.gmap_canvas {overflow:hidden;background:none!important;height:500px;width:600px;}",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
