import React,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { trainingPackagesCategory } from "../ConsultancyCards/Atoms";

export default function TrainingCards({ data }) {
  const [
    selectedtrainingPackagesCategory,
    setselectedtrainingPackagesCategory,
  ] = useRecoilState(trainingPackagesCategory);




  return (
    <div>
      <div className="card iso-card training-card">
        <img
          style={{ objectFit: "cover" }}
          className="card-img-top"
          src={data.thumbnail}
          alt="Card image cap"
        />
        <div className="card-body">
          {/* <h5 className="card-title">{data.title}</h5> */}

          <p className="card-text" style={{ color: "#232323" }}>
            {data.trainingHeading}
          </p>

          <a
         
            href="/subtraining"
            onClick={() => setselectedtrainingPackagesCategory(data)}
          >
            <span  className="btn btn-primary">View Details</span>
          </a>
        </div>
      </div>
    </div>
  );
}
