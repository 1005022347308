import React,{useEffect} from 'react'

export default function About() {

 window.scrollTo(0,0)
  

    return (
        <div>
              {/* page-title */}
      <div className="ttm-page-title-row" align='justify' style={{paddingTop : '50px',paddingBottom : '0px',
    backgroundImage : 'none'}}>
        <div className="container">
          <div className="row">
            <div className="col-md-12"> 
           
            </div>{/* /.col-md-12 */}  
          </div>{/* /.row */}  
        </div>{/* /.container */}                      
        {/* about-section */}
        <section className="ttm-row about-top-section clearfix" style={{ paddingTop : 0 }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-sm-12">
                <div className="position-relative" >
                  {/* ttm_single_image-wrapper */}
                  <div className="ttm_single_image-wrapper ttm-991-center res-991-mt-30">
                    <img className="img-fluid" src="https://images.pexels.com/photos/3183127/pexels-photo-3183127.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" />
                  </div>{/* ttm_single_image-wrapper end */}
                </div>
              </div>


      
              <div className="col-lg-7 col-sm-12" style={{ textAlign :'left' }}>
                <div className="pl-45 res-991-pl-0 res-991-mt-30">
                  {/* section title */}
                  <div className="section-title clearfix">
                    <div className="title-header">
                      <h4>ABOUT CONSULTANCY</h4>
                      <div className="title-desc" align='justify' >
                      <strong>Mehr Global Consultancy </strong>is one of the fastest
                       growing training and consultancy firms specialized in Management Trainings, 
                       Management Consulting, Business Process Excellence and much more.</div>
                    </div>
                    <div className="title-desc" align='justify'>
                    Whole team works with the mission to build, augment and infuse competitive advantage in organizations through rigorous mentorship and facilitation of operational excellence.</div>
                  </div>{/* section title end */}


                  <div className="row no-gutters mt-25" >
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <ul className="ttm-list ttm-list-style-icon">
                        <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content"  align='justify'><strong>M- Majestic,</strong>We are majestic by values and ethics. </span></li>
                        <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content"  align='justify'><strong>E- Ethical,</strong>We know the difference between what we have a right to do and what is right to do.</span></li>
                      </ul>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <ul className="ttm-list ttm-list-style-icon">
                        <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content"  align='justify'><strong>H- Honesty,</strong>We believe Honesty is  the keystone of business.</span></li>
                        <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content"  align='justify'><strong>R- Reliability,</strong>We strongly believe that reliability is the precondition for trust in business.</span></li>
                      </ul>
                    </div>
                  </div>


                  
                </div>
              </div>
            </div>



            {/* <div className="row mt-100 res-991-mt-50" style={{ textAlign :'left' }}>
              <div className="col-md-4">
            
                <div className="featured-icon-box left-icon icon-align-top">
                  <div className="featured-icon">
                    <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                      <i className="ti ti-info ttm-num" />
                    </div>
                  </div>
                  <div className="featured-content pl-20">
                    <div className="featured-title">
                      <h5 className="mb-0">Management Trainings</h5>
                    </div>
                    <div className="featured-desc" align='justify'>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
            
                <div className="featured-icon-box left-icon icon-align-top">
                  <div className="featured-icon">
                    <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                      <i className="ti ti-info ttm-num" />
                    </div>
                  </div>
                  <div className="featured-content pl-20" >
                    <div className="featured-title">
                      <h5 className="mb-0">Business Excellence</h5>
                    </div>
                    <div className="featured-desc" align='justify'>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
              
                <div className="featured-icon-box left-icon icon-align-top">
                  <div className="featured-icon">
                    <div className="ttm-icon ttm-icon_element-background-color-skincolor ttm-icon_element-size-xs ttm-icon_element-style-round">
                      <i className="ti ti-info ttm-num" />
                    </div>
                  </div>
                  <div className="featured-content pl-20">
                    <div className="featured-title">
                      <h5 className="mb-0">Management Consulting</h5>
                    </div>
                    <div className="featured-desc" align='justify'>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            
          </div>
        </section>
        {/* about-section end */}  </div>{/* page-title end*/}


 {/* services-section */}
 <section className="ttm-row ttm-bgcolor-darkgrey ttm-bg ttm-bgimage-yes bg-img1 about-services-section clearfix">
        <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-2 col-sm-1" />
            <div className="col-lg-6 col-md-8 col-sm-10">
              {/* section title */}
              <div className="section-title text-center with-desc clearfix">
                <div className="title-header mb-60">
                  <h5>WHY CHOOSE US</h5>
                  <h2 className="title">We creating solutions for your organization</h2>
                </div>
              </div>{/* section title end */}
            </div>
            <div className="col-lg-3 col-md-2 col-sm-1" />
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="row align-items-center ptb-50 res-991-ptb-20 iconalign-before-heading-row">
                <div className="col-md-4">
                  {/* featured-icon-box */}
                  <div className="featured-icon-box iconalign-before-heading style1">
                    <div className="featured-content">{/* featured-content */}
                      <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg"> 
                        <i className="ti ti-world" />
                      </div>


                      <div className="featured-title">
                        <h5 className="mb-0" style={{   fontSize: '20px' }}>Our Mission</h5>
                      </div>
                    </div>{/* featured-content */}
                  </div>{/* featured-icon-box */}
                </div>
                <div className="col-md-8">
                  <p className="mb-0 ml-30 res-767-mt-20 res-767-ml-10"  >
                  Our entire team works with the mission to build, augment and infuse competitive advantage in organizations through rigorous guidance, mentorship and facilitation of operational excellence.  

                  </p>
                </div>
              </div>
              <div className="row align-items-center ptb-50 res-991-ptb-20 iconalign-before-heading-row">
                <div className="col-md-4">
                  {/* featured-icon-box */}
                  <div className="featured-icon-box iconalign-before-heading style1">
                    <div className="featured-content">{/* featured-content */}
                      <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg"> 
                        <i className="ti ti-bar-chart" />
                      </div>
                      <div className="featured-title">
                        <h5 className="mb-0" style={{   fontSize: '20px' }}>Our Vision</h5>
                      </div>
                    </div>{/* featured-content */}
                  </div>{/* featured-icon-box */}
                </div>
                <div className="col-md-8">
                  <p className="mb-0 ml-30 res-767-mt-20 res-767-ml-10" >
                  Become a pioneer in the field of business excellence through continuous development and enhancement of business procedures with dedicated, customized and competent services.
                    </p>
                </div>
              </div>
              <div className="row align-items-center ptb-50 res-991-ptb-20 iconalign-before-heading-row">
                <div className="col-md-4">
                  {/* featured-icon-box */}
                  <div className="featured-icon-box iconalign-before-heading style1">
                    <div className="featured-content">{/* featured-content */}
                      <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg"> 
                        <i className="ti ti-zip" />
                      </div>
                      <div className="featured-title">
                        <h5 className="mb-0" style={{   fontSize: '20px' }}>Our Strategies</h5>
                      </div>
                    </div>{/* featured-content */}
                  </div>{/* featured-icon-box */}
                </div>
                <div className="col-md-8">
                  <p className="mb-0 ml-30 res-767-mt-20 res-767-ml-10" >
                  To exceed our customer expectations by providing extra value-added services, knowledge transfer through our seasoned and experienced consultants. 
                    </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* services-section end */}




   
{/* testimonial-section */}
<section className="ttm-row about-testimonial-section ttm-bgimage-yes map-bgimg ttm-bg ttm-bgcolor-skincolor bg-layer clearfix">
        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer" />
        <div className="container">
          {/* row */}
          <div className="row">
            <div className="col-md-12" style={{ textAlign : "center" }}>
            
            <h3 style={{ fontSize : '24px' , color : '#fafafae0' }}>"Excellence is a continuous process and not an accident"
              <br/>
                  - APJ Abdul Kalaam
                  </h3>
            </div></div></div></section>


        </div>
    )
}
