import React from "react";
import OwlCarousel from "react-owl-carousel";
import Dashboard from './dashboard.png'
import Isoft from './isoft.png'

export default function Eqms() {
  return (
    <div>
      <div
        className="ttm-page-title-row1"
        style={{
          backgroundImage:
            "url('https://2wk12w2dk3733zyjdf3secd9-wpengine.netdna-ssl.com/wp-content/uploads/2020/04/DashBoard_v9_hero.jpg')",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box ttm-textcolor-white">
                <div className="page-title-heading">
                  <h1 className="title" style={{ textTransform : 'none' }}>e-QMS</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a title="Homepage" href="index.html">
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;Home
                    </a>
                  </span>
                  <span className="ttm-bread-sep">&nbsp; | &nbsp;</span>
                  <span>e-QMS</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>

      <div className="container  legalcontainer">
        <div className="legalheading">
          <h3 className="title">ISOft (For Small enterprizes)</h3>
          <div className="legalcontent">
            <p align="justify">
              <strong>TECHNICAL FEATURES:</strong> Light weight Web based
              application, supported on Windows 10 and Mac OS, Mobile & Tablet
              compatibility. Using MongoDB Database, supported both cloud and
              in-house server. Standalone application will not impact on
              existing application. No integration required for Quality
              Assurance Modules. API compliance. Forms and Reports can be
              customized on request. Documents Management with revision control
              and revision history tracking. Automatic System Alerts and
              Notification. English/Arabic bilingual User interface. One Year
              Warranty with free upgrades and bugs handling, later AMC at
              nominal fee can be signed for proactive Technical Support.
            </p>
           
           <center> <img src={Isoft} 
            className="img-fluid"/></center>
          </div>
        </div>
        <div className="legalheading">
          <h3 className="title">IKomplianz (For medium & large enterprises)</h3>
          <div className="legalcontent">
            <p align="justify">
              Our flagship product iKomplianz is a tailormade industry solutions
              that caters to organization's all Governance, Compliance,
              Certifications and Risk management requirements Every organization
              need to comply to statutory, regulatory requirements. Every
              organization need to manage multiple compliances and
              certifications for their Information Security management (ISMS),
              Quality management (QMS, CMMI), Service management (SMS), Business
              continuity management (BCMS), Environment management (EMS), Food
              Safety, OSHAS and many more. It is a big challenge to manage
              multiple Compliances, Certifications, Internal and External
              audits, effectively running all different Management systems,
              Management reporting, KPI and Balance score card, Risk management,
              Issue management, Coordination with stakeholder’s communication,
              Document management etc. iKomplianz effectively manages all the
              above requirements and pain points seamlessly with document
              management and control.
            </p>
           <center> <img src={Dashboard} 
            className="img-fluid"/></center>
          </div>
        </div>
      </div>
    </div>
  );
}
